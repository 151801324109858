<template>
    <div>
        <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent="" scrollable  max-width="780px">
                <v-card :style="($vuetify.breakpoint.mdAndDown) ? 'width:auto;' : 'min-width:320px;'">
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto;">
                        <div style="min-height:10px;"></div>
                         <v-container grid-list-md>
                             <v-layout row wrap>
                                 <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">File name:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold"><v-chip label dark small>{{ item.name }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">File size:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold"><v-chip label dark small color="warning">{{ item.file_size_readable }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">File type:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold"><v-chip label dark small color="success">{{ item.file_type_for_humans }}</v-chip></span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Owner:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold"><v-chip label dark small>{{ item.user.name }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Created at:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold"><v-chip label dark small>{{ item.created_at }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:70px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Date limit (from - to):</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{ item.data.parameters.startDate + " - " + item.data.parameters.endDate }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Time limit (from - to):</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{ item.data.parameters.startTime + " - " + item.data.parameters.endTime }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Selected sales locations:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{getSelectedSalesLocations}}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Selected cashiers:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{getSelectedCashiers}}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Selected payment methods:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{getSelectedPaymentMethods}}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <!--<v-flex xs12 sm12 md6 lg6 v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.zoi !== null && item.data.parameters.zoi !== '' )">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">ZKI:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{ item.data.parameters.zoi }}</span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.zoi !== null && item.data.parameters.zoi !== '' )"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.eor !== null && item.data.parameters.eor !== '' )">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">JIR:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.eor !== null && item.data.parameters.eor !== '' )">
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{ item.data.parameters.eor }}</span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.eor !== null && item.data.parameters.eor !== '' )"></div>

                                <v-flex xs12 sm12 md6 lg6 v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.invoiceNr !== null && item.data.parameters.invoiceNr !== '' )">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Selected invoice number:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6 v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.invoiceNr !== null && item.data.parameters.invoiceNr !== '' )">
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{ item.data.parameters.invoiceNr }}</span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.invoiceNr !== null && item.data.parameters.invoiceNr !== '' )"></div>
                                -->

                                <v-flex xs12 sm12 md6 lg6 v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.laterFiscalization !== null && item.data.parameters.laterFiscalization !== '' )">
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Only invoices that went to fiscalization later:</span>
                                </v-flex>

                                <v-flex xs12 sm12 md6 lg6 v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.laterFiscalization !== null && item.data.parameters.laterFiscalization !== '' )">
                                    <span v-if="item.data !== undefined" class="font-weight-bold">{{ (laterFiscalization == true) ? "Yes" : "No"}}</span>
                                </v-flex>
                                <div style="min-height:30px;" v-if="item.data !== undefined && item.data.parameters !== undefined && (item.data.parameters.laterFiscalization !== null && item.data.parameters.laterFiscalization !== '' )"></div>
                                
                            </v-layout>
                         </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
    </div>
</template>

<script>
import { formatDate, randomPinNumber, getCurrentDate } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'

export default {

    name: 'export-info-dialog',
    data: () => ({
        valid: false,
        item: {},
        dialog: false,
        loading: false,
        note: null,
        formTitle: "",
        cancelText: 'Close',
        event_timestamp: null,
        currentDate: null,
        laterFiscalization: false,

        salesLocations: [],
        cashiers: [],

        selectedSalesLocations:[],
        selectedCashiers: [],
        selectedPaymentMethods: []

        
    }),

    watch : {

    },

    computed: {
        getSelectedSalesLocations() {
            if(this.selectedSalesLocations.length > 0) {
                let ssl = new Array()

                if(this.salesLocations.length > 0) {
                    this.selectedSalesLocations.forEach(item => {
                        this.salesLocations.forEach(slItem => {
                            if(item == slItem.id) {
                                ssl.push(slItem.name)
                            }
                        })
                    })
                }

                return ssl.join(", ")
            }
            return new Array("All sales locations").join(", ")
        },

        getSelectedCashiers() {
            if(this.selectedCashiers.length > 0) {
                let sc = new Array()

                if(this.cashiers.length > 0) {
                    this.selectedCashiers.forEach(item => {
                        this.cashiers.forEach(scItem => {
                            if(item == scItem.id) {
                                sc.push(scItem.name)
                            }
                        })
                    })
                }

                return sc.join(", ")
            }
            return new Array("All cashiers").join(", ")
        },

        getSelectedPaymentMethods() {
            if(this.selectedPaymentMethods.length > 0) {
                let spm = new Array();

                this.selectedPaymentMethods.forEach(item => {
                    if(item == 1) {
                        spm.push('CREDIT CARD')
                    }

                    if(item == 2) {
                        spm.push('FREE VEND')
                    }

                    if(item == 3) {
                        spm.push('CASH')
                    }
                })

                return spm.join(", ")
            }


            return new Array("All payment methods").join(", ")
        }
    },

    methods: {
        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
                this.salesLocations = response.data.salesLocations
                this.cashiers = response.data.cashiers
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        setFormTitle() {
            this.formTitle = "Export number #" + this.item.id
        },

        cancel() {
            this.dialog = false
            this.item = {}
        }
    },

    created() {
        var vm = this;
        this.currentDate = getCurrentDate()

        this.setParametersData();

        EventBus.$on('VIEW_EXPORT_ITEM_DIALOG', function(data) {
            //window.console.log(data)
            vm.item = data
            vm.setFormTitle();
            vm.dialog = true;

            if(vm.item.data !== undefined && vm.item.data.parameters.selectedSalesLocations !== undefined) {
                vm.selectedSalesLocations = vm.item.data.parameters.selectedSalesLocations
            }

            if(vm.item.data !== undefined && vm.item.data.parameters.selectedCashiers !== undefined) {
                vm.selectedCashiers = vm.item.data.parameters.selectedCashiers
            }

            if(vm.item.data !== undefined && vm.item.data.parameters.selectedPaymentMethods !== undefined) {
                vm.selectedPaymentMethods = vm.item.data.parameters.selectedPaymentMethods
            }

             if(vm.item.data !== undefined && vm.item.data.parameters.laterFiscalization !== undefined) {
                vm.laterFiscalization = vm.item.data.parameters.laterFiscalization
            }
        });
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off('VIEW_EXPORT_ITEM_DIALOG')
    },

    destroyed() {

    }

}

</script>

<style scoped>
    .v-card__title {
        background-color: #f5f5f5 !important;
    }

    span.detail-name-label {
        color: "#000" !important;
    }
</style>